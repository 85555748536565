@import '../../base.scss';
@import '../block-base.scss';

.schedule-platform-bg {
    @extend .is-bg;
    background: linear-gradient(267deg, #192225 0%, #0E313E 100%);
    .title-pic {
        @include size(705px, 455px);
        @include abs-at(40px, 0);
    }
    .title-pic-car1 {
        @include size(130px, 95px);
        @include abs-at(174px, 12px);

    }
    .title-pic-car2 {
        @include size(127px, 111px);
        @include abs-at(547px, 77px);
    }
}