@import '../../base.scss';
@import '../block-base.scss';

.perception-bg {
    @extend .is-bg;
    background: linear-gradient(241deg, #22074A 0%, #1E1B21 100%);
    .pic-title {
        @include abs-at(171px, 40px);
        @include size(528px, 288px);
    }
    .pic-title-car {
        @include abs-at(443px, 185px);
        @include size(134px, 111px);
    }
}