@import '../../base.scss';

.join {
    @include word-size($font-size-normal);
    font-weight: 500;
    padding-top: 160px;
    color: $color-deep-black;
    margin-left: 75px;
    margin-right: 75px;
    padding-bottom: 112px;
    h3 {
        @include word-size(50px);
        text-align: center;
        padding-top: 92px;
    }
    h6 {
        @include word-size(40px);
        font-weight: 500;
        padding-top: 96px;
        padding-bottom: 35px;
    }
    img {
        @include size(600px, 600px);
        @include is-border-radius;
        display: block;
        margin: auto;
        margin-top: 89px;
    }
    ul {
        margin-bottom: 97px;
        li {
            @include border-bottom-1px;
            font-weight: 500;
            line-height: 115px;
            width: 550px;
            overflow: hidden;
            white-space: nowrap;
            &:active {
                color: $color-blue;
            }
        }
    }
}
.join.is-en {
    ul {
        @include word-size(24px);

    }
}