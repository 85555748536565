@import '../../base.scss';
@import '../base.scss';

.service-block {
    color: $color-deep-black;
    padding: {
        left: $service-padding;
        right: $service-padding;
        bottom: 127px;
    }
    h3 {
        @include word-size(40px);
        line-height: 50px;
        padding-top: 126px;
    }
    .desc {
        @include word-size($font-size-normal);
        @include word-line-height-normal;
        padding-top: 71px;
    }
    img {
        @include size(600px, 342px);
        @include is-border-radius;
        margin-top: 65px;
    }
    background: $color-gray;
    &:nth-child(2n+1) {
        background: $color-white;
    }
}