@import '../../base.scss';
@import '../base.scss';

.service-banner {
    background: $color-light-black;
    color: $color-white;
    padding: {
        left: $service-padding;
        right: $service-padding;
        top: 112px;
        bottom: 90px;
    }

    h3 {
        @include word-size(50px);
        text-align: center;
    }

    .desc {
        @include word-size($font-size-normal);
        @include word-line-height-normal;
        margin-top: 67px;
    }

}

.service-img-wrap {
    @include size(750px, 422px);
    background: $color-light-black;
}
.service-img {
    @include size(750px, 422px);
    background: $color-light-black;
}