
@import '../../base.scss';

$margin-lr: 75px;

.history {
    color: $color-deep-black;
    margin: {
        left: 75px;
        right: 75px;
    }
    h3 {
        padding-top: 127px;
        @include word-size(40px);
    }
    .date {
        @include word-size(90px);
        font-weight: 500;
        margin-top: 111px;
    }
    .detail {
        @include word-line-height-normal;
        margin-top: 60px;
        height: 150px;
    }
    .pic {
        @include size(600px, 600px);
        border-radius: 130px 130px 130px 50px;
    }
    .time-bar {
        @include abs-parent;
        @include is-border-radius;
        overflow: hidden;
        display: flex;
        margin-top: 54px;
        margin-bottom: 90px;
        .time-bar-before {
            @include abs;
            @include size(100%, 100%);
            display: flex;
            >*{
                flex-grow: 1;
                text-align: center;
                &:nth-child(2n) {
                    background: #D7D7D2;
                }
                &:nth-child(2n+1) {
                    background: #E4E4E0;
                }
            }
        }
        .time-bar-active {
            @extend .time-bar-before;
            >* {
                &:nth-child(2n) {
                    background: #C7DBFD;
                }
                &:nth-child(2n+1) {
                    background: #A6C5FC;
                } 
            }
        }
        >*{
            @include word-size(20px);
            position: relative;
            z-index: 2;
            flex-grow: 1;
            text-align: center;
            line-height: 30px;
        }
    }
    .btn-grp {
        text-align: right;
        .prev, .next {
            @include size(70px, 70px);
            border: none;
        }
        .prev {
            margin-right: 75px;
        }
        .next {
            margin-right: 35px;
        }
    }
}

.history.is-en {
    .detail {
        @include word-size(24px);
        margin-top: 30px;
        height: 170px;
    }
}