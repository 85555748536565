@import '../../base.scss';

.top {
    height: 200px;
}
.explode {
    position: relative;
    //zoom: 0.3;
    transform: scale(0.3);
    transform-origin: 0 0;
    width: 1656px;
    height: 856px;
    cursor: pointer;
    // background-size: 100% 100%;
    // background-image: url('./img/0.png');
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    img {
        position: absolute;
        transition-property: transform;
        transition-duration: .5s;
        // transition-timing-function: cubic-bezier(0.1, 0.2, 0.8, 0.95);
        display: none;
        
    }
    .img0 {
        @include size(1656px, 856px);
        z-index: -1;
        display: block;
    }
    .img1 {
        @include size(363px, 301px);
        top: 260px;
        left: 521px;
        //transform: translate3d(-100px, 60px, 100px);
        z-index: 99;
        display: block;
    }
    .img2 {
        @include size(984px, 445px);
        top: 150px;
        left: 120px;
        display: block;
        //transform: translate3d(-50px, 30px, 50px);
    }
    .img3 {
        @include size(376px, 249px);
        //display: none;
        left: 1200px;
        top: 435px;
        display: block;
    }
    .img4 {
        @include size(479px, 441px);
        //display: none;
        left: 1095px;
        top: 210px;
        display: block;
    }
    .img5 {
        @include size(264px, 527px);
        left: 1290px;
        top: -120px;
        display: block;
    }
    .img6 {
        @include size(493px, 726px);
        top: -280px;
        left: 1100px;
        display: block;   
    }
    .img7 {
        @include size(148px, 113px);
        left: 1220px;
        top: -80px;
        display: block;
    }
    .img8 {
        @include size(145px, 72px);
        //display: none;
        left: 1520px;
        top: -230px;
        z-index: 1;
        display: block;
    }
    .img9 {
        @include size(399px, 349px);
        left: 120px;
        top: -5px;
        z-index: -2;
        display: block;
    }
    .img10 {
        @include size(435px, 572px);
        left: 60px;
        top: -415px;
        z-index: -1;
        display: block;
    }
    .img11 {
        @include size(140px, 67px);
        top: -300px;
        left: -5px;
        z-index: -1;
        display: block;
    }
    .img12 {
        @include size(108px, 75px);
        top: -430px;
        left: 380px;
        z-index: -2;
        display: block;
    }
    .img13 {
        @include size(1318px, 819px);
        top: -410px;
        left: 110px;
        z-index: -1;
        display: block;
    }
    .img14 {
        @include size(137px, 72px);
        top: -335px;
        left: 1273px;
        display: block;
    }

    &.animated {
        .img1 {
            transform: translate3d(-200px, 120px, 200px);
        }
        .img2 {
            transform: translate3d(-100px, 60px, 100px);
        }
        .img3 {
            transform: translate3d(200px, 100px, 0px);
        }
        .img4 {
            transform: translate3d(100px, 50px, 0);
        }
        .img5 {
            transform: translate3d(300px, -120px, 0);
        }
        .img6 {
            transform: translate3d(140px, -100px, 0);
        }
        .img7 {
            transform: translate3d(230px, -120px, 0);
        }
        .img8 {
            transform: translate3d(230px, -120px, 0);
        }
        .img9 {
            transform: translate3d(-180px, -20px, 0);
        }
        .img10 {
            transform: translate3d(-160px, -100px, 0);
        }
        .img11 {
            transform: translate3d(-240px, -130px, 0);
        }
        .img12 {
            transform: translate3d(-180px, -130px, 0);
        }
        .img13 {
            transform: translate3d(0, -100px, 0);
        }
        .img14 {
            transform: translate3d(140px, -170px, 0);
        }
    }
}