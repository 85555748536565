@import '../../base.scss';
@import '../index-base.scss';

.about-us {
    //@include size(750px, 1103px);
    @include word-white;
    background: linear-gradient(180deg, #226BE2 0%, #1751AD 100%);
    padding-bottom: 86px;
    h3 {
        @extend .index-page-title;
    }
    .desc {
        @extend .index-page-word;
    }
    .pic {
        @include abs-parent;
        @include size(669px, 322px);
        margin: auto;
        margin-top: 161px;
        .car {
            position: relative;
            z-index: 1;
            @include size-as-parent;
        }
        .points {
            @include abs;
            @include size(283px, 283px);
            right: 13px;
            top: -118px;
            z-index: 0;
        }
    }
    .btn {
        @extend .index-page-btn;
        margin-top: 89px;
        &:active {
            color: $color-deep-black; 
        }
    }
}