@import '../../base.scss';

$margin-lr: 75px;

.honor {
    @include word-size(40px);
    font-weight: 500;
    width: 750px;
    overflow: hidden;
    h3 {
        margin: {
            left: $margin-lr;
            right: $margin-lr;
        }
        padding-top: 126px;
    }
    ul.pic {
        display: flex;
        margin: {
            top: 112px;
            left: $margin-lr;
            right: $margin-lr;
        }
        padding: {
            left: 37px;
            right: 37px;
        }
        li {
            img {
                @include size(528px, 354px);
                @include is-border-radius;
            }
            .title {
                @include word-size(24px);
                @include word-line-height-normal;
                font-weight: 500;
                margin-top: 35px;
                margin-left: 37px;
                margin-right: 37px;
            }
            & + li {
                padding-left: 30px;
            }
        }
    }

    ul.dot {
        margin: auto;
        margin-top: 117px - 24px *2.8;
        margin-bottom: 77px; 
        display: flex;
        justify-content: center;
        li {
            @include size(12px, 12px);
            @include is-border-radius;
            background: $color-light-blue;
            &.active {
                background: $color-blue;
            }
            & + li {
                margin-left: 8px;
            }
        }
    }
    .btn-grp {
        text-align: right;
        padding-bottom: 70px;
        img {
            @include size(70px, 70px);
        }
        .prev {
            margin-right: 75px;
        }
        .next {
            margin-right: 112px;
        }
    }
}