@mixin size($width, $height) {
    width: $width;
    height: $height;
}

@mixin size-as-parent {
    @include size(100%, 100%);
}

@mixin bg-size($width, $height) {
    @include size($width, $height);
    background-size: 100% 100%;
}

@mixin abs-parent {
    position: relative;
}

@mixin abs {
    position: absolute;
}

@mixin abs-at($left, $top) {
    position: absolute;
    left: $left;
    top: $top;
}

@mixin abs-valign-middle {
    @include abs;
    top: 50%;
    transform: translateY(-50%);
}

@mixin abs-align-middle {
    @include abs;
    left: 50%;
    transform: translateX(-50%);
}

@mixin abs-center {
    @include abs-align-middle;
    @include abs-valign-middle;
    transform: translate(-50%, -50%);
}

@mixin margin-l-35 {
    margin-left: 35px;
}

@mixin margin-r-35 {
    margin-right: 35px;
}

@mixin margin-normal {
    margin-left: $margin-normal-px;
    margin-right: $margin-normal-px;
}

@mixin word-white {
    color: $color-white;
}

@mixin word-size($font-size) {
    font-size: $font-size;
}

@mixin word-line-height-normal {
    line-height: 1.4em;
}

@mixin content-block {
    content: "";
    display: block;
}

@mixin is-border-radius($px: $border-radius-px) {
    border-radius: $px;
}

@mixin border-top-1px($color: $color-deep-black) {
    @include abs-parent;
    overflow: hidden;
    &::before {
        @include abs;
        @include content-block;
        @include size(200%, 2px);
        left: 0;
        transform-origin: 0 0;
        scale: 0.5;
        background: $color;
    }
}

@mixin border-bottom-1px($color: $color-deep-black) {
    @include abs-parent;
    &::before {
        @include abs;
        @include content-block;
        @include size(200%, 2px);
        left: 0;
        bottom: 0;
        transform-origin: 0 0;
        scale: 0.5;
        background: $color;
    }
}

$border-radius-px: 20px;

$margin-normal-px: 76px;

$color-white: #fff;
$color-blue: #0855F2;
$color-light-blue: #96B5F6;
$color-deep-black: #171717;
$color-light-black: #232323;
$color-gray: #F3F2F2;
$color-black: #05042B;

$font-size-normal: 28px;

