@import '../base.scss';

.footer {
    @include size(750px, 700px);
    @include abs-parent;
    color: $color-white;
    background: linear-gradient(0deg, #174CBA 0%, #2866E7 100%);
    .logo {
        @include abs-at($margin-normal-px, 56px);
        @include size(137px, 61px);
    }
    .wechat-wrap {
        @include abs-at(570px, 77px);
        .word {
            @include word-size(14px);
            @include word-line-height-normal;
            @include abs-at(50%, 215px);
            text-align: center;
            transform: translate(-50%);
            width: 184px;
            color: $color-deep-black;
        }
        .wechat {
            @include size(40px, 32px);
        }
        .qrcode {
            @include size(223px, 284px);
            @include abs-at(-92px, -305px);
            @include is-border-radius(8px);
            z-index: 2;
            background: rgba(255,255,255,0.9);
            cursor: pointer;
            .code {
                @include size(181px, 181px);
                @include abs-at(21px, 21px);
            }
        }
    }
    ul {
        @include word-size($font-size-normal);
        display: flex;
        a {
            color: $color-white;
            text-decoration: none;
            &:active {
                color: $color-deep-black;
            }
        }
        li + li {
            margin-left: 70px;
        }

        &.menu-left {
            @include abs-at($margin-normal-px, 197px);
        }
        &.menu-right {
            @include abs-at($margin-normal-px, 298px);
        }
        &.contact-infomation {
            display: block;
            @include abs-at(50%, 372px);
            @include word-size(24px);
            @include border-top-1px($color-white);
            transform: translate(-50%);
            width: 600px;
            li {
                display: flex;
                width: 100%;
                &:nth-child(1) {
                    padding-top: 58px;
                }
                & + li {
                    margin-top: 27px;
                    margin-left: 0;
                }
                span + span {
                    margin-left: 38px;
                }
            }
        }

    }
    .ipc {
        @include word-size(21px);
        @include border-top-1px($color-white);
        @include abs-at(50%, 546px);
        transform: translate(-50%);
        width: 590px;
        padding-top: 50px;
        line-height: 120px;
        text-align: center;
        text-decoration: none;
    }
}

.footer.is-en {
    ul.contact-infomation  {
        li span:nth-child(1) {
            display: block;
            width: 90px;
        }
        li span:nth-child(2) {
            display: block;
            width: 520px;
        }
    }
    ul.menu-left, ul.menu-right {
        li {
            width: 150px;
            overflow: hidden;
        }
    }
}