@import '../../base.scss';
@import '../index-base.scss';

.video {
    @include size(750px, 750px);
    @include abs-parent;
    background-color: #161616;
    .slogan {
        @include abs-center;
        @include word-white;
        @include word-line-height-normal;
        @include word-size($slogan-font-size);
        font-weight: 500;
        white-space: nowrap;
        text-align: center;
        z-index: 4;
        transition-property: transform;
        transition-duration: 300ms;
    }
    .mask {
        @include abs;
        @include size(100%, 100%);
        background: rgba(0, 0, 0, .3);
        z-index: 3;  
    }
    .play-btn {
        @include abs-center;
        @include size(100px, 100px);
        z-index: 10;
        display: none;
    }
    .baidu-box-pic {
        @include size(100%, 100%);
    }
    video {
        @include abs;
        @include size(100%, 100%);
        object-fit: cover;
        z-index: 2;
    }
    .loading {
        @include abs-center;
        z-index: 1;
    }

    &.manual-play {
        .slogan {
            transform: translate(-50%, -200px);
        }
        .play-btn {
            display: block;
        }
    }
}