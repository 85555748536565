@import '../../base.scss';
@import '../block-base.scss';

.deep-learn-bg {
    @extend .is-bg;
    background: linear-gradient(267deg, #0C47C4 1%, #2964E0 100%);

    .deep-learn-pic {
        @include size(486px, 434px);
        @include abs-at(213px, 40px);
    }
    .deep-learn-pic-car {
        @include size(183px, 77px);
        @include abs-at(288px, 246px);
    }
}