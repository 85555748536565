@import '../../base.scss';
@import '../index-base.scss';

.technology {
    padding-bottom: 86px;
    color: $color-deep-black;
    width: 750px;
    overflow: hidden;
    h3 {
        @extend .index-page-title;
    }
    .desc {
        @extend .index-page-word;
        white-space:pre-line;
    }
    .pic {
        @include size(724px, 470px);
        margin-left: 110px;
        margin-top: 48px;
    }
    .btn {
        @extend .index-page-btn;
        color: $color-blue;
        margin-top: 34px;
        &:active {
            color: $color-deep-black;
        }
    }
}