@import '../base.scss';

.news-banner {
    @include size(750px, 450px);
    @include abs-parent;
    img {
        @include size(100%, 100%);
    }
    h3 {
        @include abs-center;
        @include word-size(50px);
        font-weight: 500;
        color: $color-white;
    }
}

.news-grp {
    @include margin-l-35;
    @include margin-r-35;
    padding-bottom: 60px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
        @include is-border-radius;
        overflow: hidden;
        margin-top: 30px;
        background: $color-gray;
        color: $color-deep-black;
        img {
            @include size(330px, 330px);
        }
        h6 {
            @include word-size(22px);
            @include word-line-height-normal;
            font-weight: 600;
            max-width: 290px;
            height: 90px;
            margin: auto;
            margin-top: 15px;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 3;
            -webkit-box-orient:vertical;
            display:-webkit-box;
        }
        .date {
            @include word-size(20px);
            padding-top: 14px;
            margin-left: 21px;
            padding-bottom: 23px;
        }
        &:hover {
            h6 {
                color: $color-blue;
            }
        }
    }
}