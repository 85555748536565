@import '../../base.scss';

.contact {
    @include size(750px, 997px);
    @include abs-parent;
    background: #030E1C;
    color: $color-white;
    .bg {
        @include bg-size(100%, 100%);
    }

    h3 {
        @include word-size(50px);
        @include abs-align-middle;
        top: 109px;
        font-weight: 500;
    }
    .contact-info {
        @include abs;
        @include word-size($font-size-normal);
        left: 270px;
        .msg {
            @include word-line-height-normal;
            margin-top: 22px;
            width: 360px;
        }
        img {
            @include abs-at(-68px, -7px);
            @include size(40px, 40px);
        }
        &:nth-child(1) {
            top: 285px;
        }
        &:nth-child(2) {
            top: 426px;
        }

        &:nth-child(3) {
            top: 569px;
        }
    }
    .car {
        @include abs-at(174px, 829px);
        @include size(544px, 325px);
        z-index: 1;
    }
}