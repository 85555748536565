@import '../../base.scss';

.pic {
    @include size(750px, 800px);
    @include word-size(50px);
    text-align: center;
    margin-top: 112px;
    .explode {
        margin-top: 80px;
        margin-left: 120px;
    }
}