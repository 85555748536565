@import '../block-base.scss';

.hardware-bg {
    @extend .is-bg;
    background: linear-gradient(267deg, #025584 1%, #012739 100%);
    .title-pic {
        @include abs-at(88px, 20px);
        @include size(611px, 350px);
    }
    .title-pic-car {
        @include abs-at(384px, 122px);
        @include size(162px, 142px);
    }
}