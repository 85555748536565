@import '../base.scss';

.banner {
    @include size(750px, 108px);
    @include abs-parent;
    position: fixed;
    top: 0;
    z-index: 100;
    background: $color-white;
    transition-property: height;
    transition-duration: 500ms;
    .logo {
        @include bg-size(137px, 60px);
        @include abs-at(35px, 23px);
    }
    .menu-btn {
        @include bg-size(50px, 40px);
        @include abs-at(666px, 34px);
    }
    &.expand {

        @include size(750px, 100vh);
    }
}
.banner-place-holder {
    @include size(750px, 108px);
}
.menu {
    @include word-size(32px);
    @include abs-at(0, 108px);
    width: 100%;
    z-index: 101;
    line-height: 125px;
    color: $color-deep-black;
    text-align: center;
    padding-top: 30px;
    li a {
        display: block;
        width: 100%;
        height: 100%;
        color: $color-deep-black;
        text-decoration: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        font-weight: 600;
    }
    li a:active {
        color: $color-blue;
    }

    li {
        .is-ch {
            .chinese {
                color: $color-blue;
            }
            .en {
                color: $color-deep-black;
            }
        }
        .is-en {
            .chinese {
                color: $color-deep-black;
            }
            .en {
                color: $color-blue;
            }
        }
    }
}