@import '../../base.scss';
@import '../block-base.scss';

.planning-bg {
    @extend .is-bg;
    background: linear-gradient(267deg, #022BA0 1%, #02102C 100%);
    .title-pic {
        @include size(692px, 217px);
        @include abs-at(58px, 50px);
    }
    .title-pic-car {
        @include size(82px,89px);
        @include abs-at(314px, 62px);
    }
}