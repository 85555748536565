@import '../../base.scss';
@import '../index-base.scss';

.service {
    @include abs-parent;
    ///background: $color-deep-black;
    color: $color-white;
    padding-bottom: 87px;
    &::before {
        @include content-block;
        @include abs;
        @include size(100%, 100%);
        background: $color-deep-black;
        z-index: -2;
    }
    .bg {
        @include abs;
        @include size(750px, 600px);
        z-index: -1;
    }
    h3 {
        //@include abs-parent;
        @extend .index-page-title;
    }
    .desc {
        @extend .index-page-word;
    }
    .menu {
        @include margin-l-35;
        @include margin-r-35;
        @include is-border-radius;
        margin-top: 100px;
        background: $color-white;
        padding: 0 45px;
        color: $color-deep-black;
        .item {
            
            .item-line {
                @include abs-parent;
                .item-icon {
                    @include abs-at(519px, 25px);
                    //@include abs-valign-middle;
                    right: 0;
                    width: 71px;
                    height: 71px;
                }
                &.expand {
                    .item-icon {
                        transform: rotate(180deg);
                    }
                }
            }
            
            .item-title {
                @include word-size(32px);
                line-height: 120px;
            }
            .item-content {
                overflow: hidden;
            }
            .item-desc {
                @include word-size($index-desc-font-size);
                @include word-line-height-normal;
            }
            .item-pic {
                @include size(100%, 100%);
                @include is-border-radius;
                margin-top: 29px;
                margin-bottom: 29px;
            }
            &+.item {
                @include border-top-1px;
            }
        }
    }
}