@import '../../base.scss';
@import '../index-base.scss';

.partner {
    background: $color-gray;
    padding-bottom: 88px;
    h3 {
        //@include abs-parent;
        @extend .index-page-title;
    }
    .list {
        @include margin-l-35;
        @include margin-r-35;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 60px;
        * {
            @include size(210px, 88px);
        }
        img {
            @include is-border-radius;
            width: 210px;
            margin-top: 35px;
            &:nth-child(1), &:nth-child(2), &:nth-child(3) {
                margin-top: 0;
            }
        }
    }
}