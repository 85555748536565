@import '../../base.scss';
@import '../block-base.scss';

.simulation-bg {
    @extend .is-bg;
    background: linear-gradient(267deg, #3E27B3 1%, #6B61F4 100%);
}
.title-pic {
    @include size(576px, 229px);
    @include abs-at(165px, 79px);
}
.title-pic-car {
    @include size(265px, 86px);
    @include abs-at(331px, 109px);
}