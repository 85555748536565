body {
    overscroll-behavior: none;
}

* {
    user-select: none;
}
a {
    text-decoration: none;
    color: inherit;
}