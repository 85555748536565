$slogan-font-size: 30px;

$index-title-font-size: 50px;
$index-desc-font-size: 28px;
$index-btn-font-size: 34px;
$index-btn-line-height: 82px;

.index-page-title {
    @include word-size($index-title-font-size);
    padding-top: 112px;
    text-align: center;
}

.index-page-word {
    @include word-size($index-desc-font-size);
    @include word-line-height-normal;
    @include margin-normal;
    padding-top: 57px;
}

.index-page-btn {
    @include word-size($index-btn-font-size);
    @include margin-normal;
    line-height: $index-btn-line-height;
    border: 2px solid;
    border-radius: $index-btn-line-height / 2;
    text-align: center;
}