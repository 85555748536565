@import '../../base.scss';

$bg-color: rgba(16, 16, 16, .8);
.block {
    width: 750px;
    overflow: hidden;
    @include abs-parent;
    color: $color-white;
    >* {
        @include margin-normal;
    }
    h3 {
        @include word-size(40px);
        padding-top: 127px;
    }
    .description {
        @include word-size($font-size-normal);
        @include word-line-height-normal;
        padding-top: 197px;
    }

    .part-title {
        @include size(602px, 95px);
        @include word-size($font-size-normal);
        @include word-line-height-normal;
        @include abs-parent;
        box-sizing: border-box;
        padding-top: 26px;
        text-align: center;
        margin-top: 86px;
        margin-bottom: 25px;
        white-space: pre-line;
        &::before {
            @include abs;
            @include bg-size(602px, 95px);
            content: '';
            background-image: url('./核心技术-小标题框-白色到透明.svg');
            top: 0;
            left: 0;
        }
    }
    .part-description {
        @include word-size($font-size-normal);
        @include word-line-height-normal;
        margin-top: 48px;
        height: 216px;
    }
    .part-pic-wrap {
        @include size(600px, 342px);
        overflow: hidden;
    }
    .part-pic {
        display: flex;
        flex-wrap: nowrap;

        .video-wrap {
            @include abs-parent;
        }

        .video {
            .play-btn-mask {
                @include abs-at(0, 0);
                @include size(100%, 100%);
                background: rgba(0, 0, 0, .3);
                display: none;
            }
            .play-btn {
                @include abs-center;
                @include size(70px, 70px);
                z-index: 10;
                display: none;
            }
            &.is-manual-play {
                .play-btn-mask {
                    display: block;
                }
                .play-btn {
                    display: block;
                }
            }
        }

        video, >img, .pic-group {
            @include size(600px, 342px);
            @include is-border-radius;
            flex-shrink: 0;
        }
        .pic-group {
            @include abs-parent;
            box-sizing: border-box;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 6px;
            background: $bg-color;
            .pic {
                @include abs-parent;
                @include size(292px, 161px);
                overflow: hidden;
                img {
                    @include size(100%, 100%);
                }
                .title {
                    @include abs-align-middle;
                    @include size(166px, 40px);
                    @include word-size(20px);
                    @include is-border-radius(10px);
                    line-height: 40px;
                    top: 118px;
                    text-align: center;
                    background: rgba(16, 16, 16, .8);
                }
                &:nth-child(1) {
                    border-top-left-radius: $border-radius-px;
                }
                &:nth-child(2) {
                    border-top-right-radius: $border-radius-px;
                }
                &:nth-child(3) {
                    border-bottom-left-radius: $border-radius-px;
                }
                &:nth-child(4) {
                    border-bottom-right-radius: $border-radius-px;
                }
                &:nth-child(3), &:nth-child(4) {
                    margin-top: 6px;
                }
            }
            .pic-detail {
                @include abs-at(0, 0);
                @include size(100%, 100%);
                background:$bg-color;
                .title {
                    @include abs-at(397px, 42px);
                    @include size(166px, 40px);
                    @include is-border-radius(10px);
                    @include word-size(20px);
                    text-align: center;
                    background: $bg-color;
                    line-height: 40px;
                    z-index: 1
                }
                img {
                    @include size(546px, 287px);
                    @include abs-center;
                    @include is-border-radius;
                }
            }
        }
    }

    .selector {
        //@include size(600px, 70px);
        width: 600px;
        @include abs-parent;
        padding-top: 70px;
        .prev {
            @include size(70px, 70px);
            @include abs-valign-middle;
            right: 182px;
        }
        .next {
            @include size(70px, 70px);
            @include abs-valign-middle;
            right: 37px;
        }
        .dot-grp {
            @include abs-valign-middle;
            left: 100px;
            transform: translate(-50%, -50%);
            display: flex;
            .dot {
                width: 16px;
                height: 16px;
                border-radius: 50%;
                background: #989696;
                & + .dot {
                    margin-left: 24px;
                }
                &.active {
                    background: $color-white;
                }
            }
            
        }
    }
    .padding {
        @include size(750px, 77px);
    }
}

.block.is-en {
    .part-title {

    }
    .part-description {
        @include word-size(24px);
        height: 280px;
    }
}