@import '../base.scss';

$padding-lr: 75px;

.about-us {
    .banner {
        @include size(750px, 630px);
        @include abs-parent;
        background: #08182B;
        .bg {
            @include abs-center;
            @include size(736px, 499px);
        }
        h3 {
            @include abs-align-middle;
            top: 112px;
            @include word-size(50px);
            color: $color-white;
        }
    }

    h6, .desc {
        padding: {
            left: $padding-lr;
            right: $padding-lr;
        }
    }
    
    h6 {
        @include word-size(40px);
        font-weight: 500;
        padding-top: 127px;
    }

    .desc {
        @include word-size($font-size-normal);
        @include word-line-height-normal;
        padding-top: 52px;
    }

    .corp-introduce {
        @include size(750px, 980px);
        @include abs-parent;
        background: linear-gradient(0deg, #006CEA 0%, #0053B3 100%);
        color: $color-white;
        .car-grp {
            @include abs-align-middle;
            top: 530px;
            img.car {
                @include size(669px, 322px);
                position: relative;
                z-index: 1;
            }
            img.light {
                @include abs-at(421px, -93px);
                z-index: -1;
            }
        }
    }

    .corp-culture, .corp-culture-items {
        background-color: $color-gray;
        color: $color-deep-black;
        img {
            @include size(600px, 342px);
            @include is-border-radius;
            display: block;
            margin: auto;
            margin-top: 72px;
        }
    }
    .corp-culture-items {
        padding-bottom: 127px;
        h6 {
            padding-top: 64px;
        }
    }
}
