@import '../../base.scss';
@import '../index-base.scss';

.join-us {
    padding-bottom: 86px;
    h3 {
        //@include abs-parent;
        @extend .index-page-title;
    }
    img {
        @include size(600px, 600px);
        @include is-border-radius;
        margin: auto;
        margin-top: 89px;
        display: block;
    }
    .btn {
        @extend .index-page-btn;
        color: $color-blue;
        margin-top: 87px;
        &:active {
            color: $color-deep-black;
        }
    }
}